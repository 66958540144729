<template>
   <div
      class="card card-custom card-stretch gutter-b border-top border-5"
      :class="isClosed(action.date_end) ? 'border-primary' : action.acl_confirme ? 'border-success' : 'border-warning'"
   >
      <div class="card-body">
         <div class="row">
            <div class="col-xxl-12 mb-2 text-center">
               <router-link
                  :to="{ name: 'action.view', params: { id: action.acl_id, societe: action.act_soc } }"
                  class="font-size-h5 font-weight-bold text-dark-75 text-hover-primary"
               >
                  {{ replaceHTML(action.acl_pro_libelle) }}
               </router-link>
            </div>
         </div>
         <div class="row">
            <div class="col-xxl-12 text-center">
               <span class="font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2 text-dark-50 text-hover-primary"
                  ><i class="fas fa-map-marked-alt mr-2 font-size-lg text-dark-50"></i>{{ action.act_adr_cp }}
                  {{ action.act_adr_ville }}
               </span>
            </div>
         </div>
         <div class="separator separator-solid my-4"></div>
         <div class="row">
            <div class="col-xxl-12">
               <div class="d-flex align-items-center flex-lg-fill mr-5 mb-4">
                  <span class="mr-4">
                     <i class="flaticon-interface-9 display-4 font-weight-bold text-muted"></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75">
                     <span class="font-weight-bolder font-size-sm">{{ $t('ACTION.GENERAL.HT') }}</span>
                     <span v-if="action.acl_facturation === 0" class="font-weight-bolder font-size-h5">{{ action.acl_ca | toCurrency }}</span>
                     <span v-else-if="action.acl_facturation === 1" class="font-weight-bolder font-size-h5">{{
                        action.acl_ca_gfc | toCurrency
                     }}</span>
                  </div>
               </div>
            </div>
            <div class="col-xxl-12">
               <div class="d-flex align-items-center flex-lg-fill mr-5 mb-4">
                  <span class="mr-4">
                     <i class="flaticon-home-1 display-4 font-weight-bold text-muted"></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75">
                     <span class="font-weight-bolder font-size-sm">{{ $t('ACTION.GENERAL.CUSTOMER') }}</span>
                     <span class="font-weight-bolder font-size-h5">{{ action.name }}</span>
                  </div>
               </div>
            </div>
            <div class="col-xxl-12">
               <div class="d-flex align-items-center flex-lg-fill mr-5 mb-4">
                  <span class="mr-4">
                     <i class="flaticon-calendar-1 display-4 font-weight-bold text-muted"></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75">
                     <span class="font-weight-bolder font-size-sm">{{ $t('ACTION.GENERAL.START') }}</span>
                     <span class="font-weight-bolder font-size-lg" v-if="action.date_deb">{{ action.date_deb | moment('Do MMMM YYYY') }}</span>
                  </div>
                  <div class="d-flex flex-column text-dark-75 ml-9">
                     <span class="font-weight-bolder font-size-sm">{{ $t('ACTION.GENERAL.END') }}</span>
                     <span class="font-weight-bolder font-size-lg" v-if="action.date_end">{{ action.date_end | moment('Do MMMM YYYY') }}</span>
                  </div>
               </div>
            </div>
         </div>
         <div class="row" v-if="new Date().setHours(0, 0, 0, 0) <= new Date(action.date_deb).setHours(0, 0, 0, 0)">
            <div class="col-xxl-12">
               <div class="d-flex align-items-center flex-lg-fill mr-5 mb-4">
                  <span class="mr-4">
                     <i class="flaticon-file-2 display-4 font-weight-bold text-muted"></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75">
                     <span class="font-weight-bolder font-size-sm">{{ $t('ACTION.GENERAL.CONVOCATIONS_URL') }}</span>
                     <a v-if="action.convocations_url" :href="action.convocations_url" class="font-weight-bolder font-size-lg" target="_blank">
                        {{ $t('GENERAL.DOWNLOAD') }}
                     </a>
                     <span v-else v-b-tooltip.hover title="Indisponible pour le moment." class="font-weight-bolder font-size-lg text-info">
                        <i class="fas fa-info-circle text-info"></i> {{ $t('GENERAL.NOT_AVAILABLE_YET') }}
                     </span>
                  </div>
               </div>
            </div>
         </div>
         <div class="row" v-if="new Date() >= addDays(action.date_end, 5)">
            <div class="col-xxl-12">
               <div class="d-flex align-items-center flex-lg-fill mr-5 mb-4">
                  <span class="mr-4">
                     <i class="flaticon-file-2 display-4 font-weight-bold text-muted"></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75">
                     <span class="font-weight-bolder font-size-sm">{{ $t('ACTION.GENERAL.CERTIFICATE_URL') }}</span>
                     <a v-if="action.certificate_url" :href="action.certificate_url" class="font-weight-bolder font-size-lg" target="_blank">
                        {{ $t('GENERAL.DOWNLOAD') }}
                     </a>
                     <span v-else v-b-tooltip.hover :title="tooltipMessage(action.date_end)" class="font-weight-bolder font-size-lg text-info">
                        <i class="fas fa-info-circle text-info"></i> {{ $t('GENERAL.NOT_AVAILABLE_YET') }}
                     </span>
                  </div>
               </div>
            </div>
            <div class="col-xxl-12">
               <div class="d-flex align-items-center flex-lg-fill mr-5 mb-4">
                  <span class="mr-4">
                     <i class="flaticon-folder display-4 font-weight-bold text-muted"></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75">
                     <span class="font-weight-bolder font-size-sm">{{ $t('ACTION.GENERAL.CERTIFICATE_URL_USERS') }}</span>
                     <a
                        v-if="action.certificate_url_users"
                        :href="action.certificate_url_users"
                        class="font-weight-bolder font-size-lg"
                        target="_blank"
                     >
                        {{ $t('GENERAL.DOWNLOAD') }}
                     </a>
                     <span v-else v-b-tooltip.hover :title="tooltipMessage(action.date_end)" class="font-weight-bolder font-size-lg text-info">
                        <i class="fas fa-info-circle text-info"></i> {{ $t('GENERAL.NOT_AVAILABLE_YET') }}
                     </span>
                  </div>
               </div>
            </div>
            <div class="col-xxl-12">
               <div class="d-flex align-items-center flex-lg-fill mr-5 mb-4">
                  <span class="mr-4">
                     <i class="flaticon-list-1 display-4 font-weight-bold text-muted"></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75">
                     <span class="font-weight-bolder font-size-sm">{{ $t('ACTION.GENERAL.ATTENDANCE_URL') }}</span>
                     <a v-if="action.time_card_url" :href="action.time_card_url" class="font-weight-bolder font-size-lg" target="_blank">
                        {{ $t('GENERAL.DOWNLOAD') }}
                     </a>
                     <span v-else v-b-tooltip.hover :title="tooltipMessage(action.date_end)" class="font-weight-bolder font-size-lg text-info">
                        <i class="fas fa-info-circle text-info"></i> {{ $t('GENERAL.NOT_AVAILABLE_YET') }}
                     </span>
                  </div>
               </div>
            </div>
            <div
               class="col-xxl-12"
               v-if="
                  (role === 'Gestionnaire' && currentUser.avisDeStage === 1) ||
                  currentUser.role === 'Administrateur' ||
                  currentUser.role === 'Super Admin'
               "
            >
               <div class="d-flex align-items-center flex-lg-fill mr-5 mb-4">
                  <span class="mr-4">
                     <!--                            <i class="flaticon-folder display-4 font-weight-bold text-muted"></i>-->
                     <i class="display-4 la la-star"></i>
                  </span>
                  <div class="d-flex flex-column text-dark-75">
                     <span class="font-weight-bolder font-size-sm">{{ $t('ACTION.GENERAL.INTERNSHIP_NOTICE_URL') }}</span>
                     <a v-if="action.avis_stagiaires_url" :href="action.avis_stagiaires_url" class="font-weight-bolder font-size-lg" target="_blank">
                        {{ $t('GENERAL.DOWNLOAD') }}
                     </a>
                     <span v-else v-b-tooltip.hover :title="tooltipMessage(action.date_end)" class="font-weight-bolder font-size-lg text-info">
                        <i class="fas fa-info-circle text-info"></i> {{ $t('GENERAL.NOT_AVAILABLE_YET') }}
                     </span>
                  </div>
               </div>
            </div>
         </div>
         <div class="row" v-else-if="this.$route.name === 'action.closed'">
            <div class="col-xxl-12">
               <div class="d-flex align-items-center flex-lg-fill mt-3">
                  <i class="fas fa-info-circle text-info mr-3"></i>
                  <p>
                     Dossier administratif en cours de traitement. Les documents seront disponibles à partir du
                     {{ addDays(action.date_end, 5) | moment('Do MMMM YYYY') }}
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import moment from 'moment';
export default {
   props: ['action', 'index'],
   data() {
      return {
         currentUser: this.$store.getters.currentUser,
         role: this.$store.getters.getRole.name
      };
   },
   methods: {
      isClosed(dateFin) {
         if (moment(dateFin).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
            return true;
         }
      },
      tooltipMessage(date) {
         if (moment(date).add(5, 'days').format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) {
            return (
               'Dossier administratif en cours de traitement. Les documents seront disponibles à partir du ' +
               moment(date).add(5, 'days').format('DD/MM/YYYY')
            );
         } else {
            return 'En cours de traitement administratif. En cas de besoin,contacter votre interlocuteur·rice habituel·le';
         }
      },
      addDays(date, days) {
         const result = new Date(date);
         result.setDate(result.getDate() + days);
         return result;
      },
      replaceHTML: (
         string //Remove bold tag in action name
      ) => string.replace('<b>', '').replace('</b>', '')
   }
};
</script>
