<template>
   <div class="min-h-100 d-flex flex-column h-100">
      <div class="d-flex align-items-center gutter-b ml-auto">
         <router-link
            :to="{ name: $route.name, params: { type: 'grid', closed: $route.name === 'action.closed' }, query: $route.query }"
            class="btn btn-fh btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2"
            :class="$route.params.type === 'grid' && 'active'"
         >
            <span class="svg-icon svg-icon-primary svg-icon-lg mr-0 mr-sm-1">
               <inline-svg src="/media/svg/icons/Layout/Layout-4-blocks.svg" />
            </span>
            <span class="d-none d-sm-inline">
               {{ $t('ACTION.GENERAL.GRID') }}
            </span>
         </router-link>
         <router-link
            :to="{ name: $route.name, params: { type: 'list', closed: $route.name === 'action.closed' }, query: $route.query }"
            class="btn btn-fh btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2"
            :class="$route.params.type === 'list' && 'active'"
         >
            <span class="svg-icon svg-icon-primary svg-icon-lg mr-0 mr-sm-1">
               <inline-svg src="/media/svg/icons/Text/Bullet-list.svg" />
            </span>
            <span class="d-none d-sm-inline">
               {{ $t('ACTION.GENERAL.TABLE') }}
            </span>
         </router-link>
      </div>
      <template>
         <div class="row" v-if="$route.params.type === 'grid' || $route.params.type === 'list'">
            <div class="col-xxl-8">
               <div class="card card-custom gutter-b">
                  <div class="card-header border-0 pt-5 pb-5">
                     <h3 class="card-title align-items-start justify-content-center flex-column">
                        <span class="card-label font-weight-bolder text-dark">{{ $t('GENERAL.FILTER') }}</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-sm">
                           {{
                              Object.keys($route.query).length === 0
                                 ? $t('ACTION.GENERAL.FILTER_DESC', {
                                      number: actions.length
                                   })
                                 : `${actions.length} ${$t('GENERAL.RESULTS')}`
                           }}</span
                        >
                     </h3>
                     <div class="card-toolbar">
                        <router-link
                           v-if="isFiltered()"
                           :to="{ name: $route.name, params: $route.params }"
                           class="btn btn-sm btn-info font-weight-bold text-uppercase px-lg-9 py-lg-3 mr-3"
                        >
                           {{ $t('GENERAL.RESET') }}
                        </router-link>

                        <button
                           type="button"
                           v-on:click="triggerDisplayFilter"
                           class="btn btn-sm btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3"
                        >
                           {{ displayFilter ? $t('GENERAL.HIDE_FILTER') : $t('GENERAL.SHOW_FILTER') }}
                        </button>
                     </div>
                  </div>

                  <transition name="slide-fade">
                     <div v-if="displayFilter">
                        <form @submit="onSubmit">
                           <div class="card-body">
                              <div class="row">
                                 <div class="col-xl-3">
                                    <div class="form-group">
                                       <label>{{ $t('ACTION.LABEL.CUSTOMER') }}</label>
                                       <b-form-input class="form-control form-control-solid" v-model="form.customer" />
                                       <span class="form-text text-muted">{{ $t('ACTION.TIP.CUSTOMER') }}</span>
                                    </div>
                                 </div>
                                 <div class="col-xl-3">
                                    <div class="form-group">
                                       <label>{{ $t('ACTION.LABEL.LOCATION') }}</label>
                                       <b-form-input class="form-control form-control-solid" v-model="form.city_or_zip_code" />
                                       <span class="form-text text-muted">{{ $t('ACTION.TIP.LOCATION') }}</span>
                                    </div>
                                 </div>
                                 <div class="col-xl-3">
                                    <div class="form-group">
                                       <label>{{ $t('ACTION.LABEL.START') }}</label>
                                       <b-form-datepicker
                                          :min="this.$route.name === 'action.closed' ? '' : new Date()"
                                          :max="this.$route.name === 'action.closed' ? yesterday : ''"
                                          class="form-control form-control-solid"
                                          placeholder="DD/MM/YYYY"
                                          label-help="Utilisez les touches de votre clavier pour choisir la date"
                                          label-no-date-selected="Aucune date sélectionnée"
                                          autocomplete="off"
                                          v-model="form.start"
                                       ></b-form-datepicker>
                                       <span class="form-text text-muted">{{ $t('ACTION.TIP.START') }}</span>
                                    </div>
                                 </div>
                                 <div class="col-xl-3">
                                    <div class="form-group">
                                       <label>{{ $t('ACTION.LABEL.END') }}</label>
                                       <b-form-datepicker
                                          :min="this.$route.name === 'action.closed' ? '' : new Date()"
                                          :max="this.$route.name === 'action.closed' ? yesterday : ''"
                                          class="form-control form-control-solid"
                                          placeholder="DD/MM/YYYY"
                                          label-help="Utilisez les touches de votre clavier pour choisir la date"
                                          label-no-date-selected="Aucune date sélectionnée"
                                          autocomplete="off"
                                          v-model="form.end"
                                       ></b-form-datepicker>
                                       <span class="form-text text-muted">{{ $t('ACTION.TIP.END') }}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="card-footer py-3">
                              <div class="row">
                                 <div class="col-xxl-12 text-center">
                                    <button
                                       type="submit"
                                       class="btn btn-xs btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3"
                                       :class="sending && 'disabled'"
                                    >
                                       <i v-if="sending" class="fas fa-spinner fa-spin"></i>
                                       {{ $t('GENERAL.FILTER') }}
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </form>
                     </div>
                  </transition>
               </div>
            </div>
            <div class="col-xxl-4">
               <ActionLegend />
            </div>
         </div>
         <div class="row" v-if="$route.params.type === 'grid'">
            <div class="col-xxl-12" v-if="loading">
               <Skeleton1 />
            </div>
            <template v-else-if="!loading && paginatedItems && paginatedItems.length > 0">
               <div class="col-xxl-4 col-lg-6" v-for="(action, index) in paginatedItems" v-bind:key="index">
                  <ActionCard :action="action" :index="index" />
               </div>
            </template>
            <div v-else-if="!loading && paginatedItems && paginatedItems.length === 0" class="col-xxl-12">
               <div class="alert alert-info mx-auto w-100 w-xxl-50 text-center">
                  {{ $t('GENERAL.NO_RESULT') }}
               </div>
            </div>
         </div>
         <div class="row" v-if="$route.params.type === 'list'">
            <div class="col-xxl-12">
               <div class="card gutter-b">
                  <div class="card-body p-0">
                     <Skeleton3 v-if="loading" />
                     <ActionTable v-else-if="actions && actions.length > 0" :sortBy="sortBy" @change-sort="changeSort" />
                     <div v-else-if="!loading && actions && actions.length === 0" class="col-xxl-12">
                        <div class="alert alert-info mx-auto my-3 w-50 text-center">
                           {{ $t('GENERAL.NO_RESULT') }}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="row mt-auto" v-if="$route.params.type === 'grid'">
            <div class="col-xxl-12">
               <div>
                  <b-pagination
                     class="mb-0"
                     align="center"
                     size="lg"
                     @change="onPageChanged"
                     :total-rows="this.actions.length"
                     :per-page="this.perPage"
                     v-model="this.currentPage"
                  >
                  </b-pagination>
               </div>
            </div>
         </div>
      </template>
   </div>
</template>

<script>
import { SET_TOOLBAR, RESET_TOOLBAR } from '@/core/services/store/config.module.js';
import { SET_PAGETITLE } from '@/core/services/store/pagetitle.module';
import { LIST_ACTIONS, RESET_ACTION_STATE } from '@/core/services/store/api-orion/action';
import Skeleton1 from '@/view/content/skeleton/Skeleton1';
import Skeleton3 from '@/view/content/skeleton/Skeleton3';
import ActionCard from '@/view/pages/action/widget/Card';
import ActionTable from '@/view/pages/action/widget/Table';
import ActionLegend from '@/view/pages/action/widget/Legend';

export default {
   data() {
      return {
         currentPage: 1,
         type: 'grid',
         sortBy: null,
         perPage: 9,
         displayFilter: false,
         loading: true,
         sending: false,
         form: {
            customer: this.$route.query.customer,
            city_or_zip_code: this.$route.query.city_or_zip_code,
            start: this.$route.query.start,
            end: this.$route.query.end
         },
         paginatedItems: null,
         yesterday: new Date(new Date().setDate(new Date().getDate() - 1)),
         actions: []
      };
   },
   components: {
      Skeleton1,
      Skeleton3,
      ActionCard,
      ActionTable,
      ActionLegend
   },
   mounted() {
      this.getActions();
      this.loading = true;
      this.resetError;
      this.setType();
      this.setPageTitle();
      this.setToolbar();
      if (this.isFiltered()) this.displayFilter = true;
      // this.paginate(this.perPage, 0)
   },
   beforeDestroy() {
      this.$store.dispatch(RESET_ACTION_STATE);
   },
   methods: {
      paginate(page_size, page_number) {
         this.sortBy = '-start';

         if (this.$route.name === 'action.closed') {
            //sort this.actions by date_deb the newest first
            this.sortBy = 'start';
            this.actions.sort((a, b) => {
               return new Date(b.date_deb) - new Date(a.date_deb);
            });
         }
         if (this.actions) {
            this.paginatedItems = this.actions.slice(page_number * page_size, (page_number + 1) * page_size);
         }
      },
      onPageChanged(page) {
         this.paginate(this.perPage, page - 1);
      },
      isFiltered() {
         if (Object.keys(this.$route.query).length > 0) {
            return !(this.$route.query.page && Object.keys(this.$route.query).length == 1);
         } else {
            return false;
         }
      },
      setPageTitle() {
         this.$store.dispatch(SET_PAGETITLE, this.$route.name === 'action.closed' ? this.$t('MENU.CLOSED_TRAININGS') : this.$t('MENU.PLANNING'));
      },
      setToolbar() {
         switch (this.$route.params.type) {
            case 'list':
               this.$store.dispatch(SET_TOOLBAR, this.$t('TIP.ACTION.TABLE'));
               break;
            default:
               this.$store.dispatch(RESET_TOOLBAR);
               break;
         }
      },
      setType() {
         !['list', 'grid'].includes(this.$route.params.type) && this.$router.push({ name: '404' });
      },
      async getActions() {
         const params = {};
         if (this.$route.name === 'action.closed') {
            params['end'] = new Date(Date.now()).toISOString().split('T')[0];
         } else {
            params['start'] = new Date(Date.now()).toISOString().split('T')[0];
         }
         if (this.$route.query.customer) params['customer'] = this.$route.query.customer;
         if (this.$route.query.city_or_zip_code) params['ville'] = this.$route.query.city_or_zip_code;
         if (this.$route.query.start) params['start'] = this.$route.query.start;
         if (this.$route.query.end) params['end'] = this.$route.query.end;

         await this.$store
            .dispatch(LIST_ACTIONS, { params })
            .then(async (response) => {
               this.actions = response;
               await this.paginate(this.perPage, 0);
               this.loading = false;
            })
            .catch((error) => {
               if (error.status === 404) {
                  this.$router.push({ name: '404' });
               }
            });
      },
      onSubmit(e) {
         this.sending = true;
         const routerParams = {};
         if (this.$route.query.page) routerParams.page = 1;
         if (this.form.customer) routerParams.customer = this.form.customer;
         if (this.form.city_or_zip_code) routerParams.city_or_zip_code = this.form.city_or_zip_code;
         if (this.form.start) routerParams.start = this.form.start;
         if (this.form.end) routerParams.end = this.form.end;
         this.$router
            .push({
               name: this.$route.name,
               params: this.$route.params,
               query: routerParams
            })
            .then((this.sending = false))
            .catch(() => (this.sending = false));
         e.preventDefault();
      },
      triggerDisplayFilter() {
         this.displayFilter = !this.displayFilter;
      },
      changeSort(payload) {
         this.sortBy = payload.dir ? `-${payload.sortBy}` : payload.sortBy;
         this.getActions();
      }
   },
   beforeRouteChange(to, from, next) {
      this.$store.dispatch(RESET_TOOLBAR).then(next);
   }
};
</script>
